import React, { useEffect, useState } from "react";
import Loading from "../components/common/Loading";
import InfiniteScroll from "react-infinite-scroll-component";
import { httpReauest } from "../utils/httpRequest";
import { Shamsi } from "basic-shamsi";
import { CDN_BASE_URL } from "../config";
import CustomModal from "../components/common/Modal";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import { priceComma } from "../utils/priceComma";
import OrderCard from "../components/common/card/OrderCard";

const OrdersPage = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState();
  const [hasmore, sethasmore] = useState(true);
  const [show, setShow] = useState(false);
  const [selected, setselcted] = useState();
  const [step, setstep] = useState(1);
  const [postCode, setpostCode] = useState();

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setselcted(data);
    setShow(true);
  };

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    setLoading(true);
    const user = JSON.parse(localStorage.getItem("user"));

    await httpReauest(
      "GET",
      "/order",
      {},
      {
        "x-access-token": user.token,
      }
    ).then(({ data }) => {
      setProducts(data.data);
    });
    setLoading(false);
  }

  async function fetchData() {
    const page = products.length / 10 + 1;
    const user = JSON.parse(localStorage.getItem("user"));

    await httpReauest(
      "GET",
      "/order?page=" + page + "&limit=10",
      {},
      {
        "x-access-token": user.token,
      }
    ).then(({ data }) => {
      setProducts((cur) => [...cur, ...data.data]);
      if (data?.data?.length < 10) {
        sethasmore(false);
      }
    });
  }

  async function approve() {
    const user = JSON.parse(localStorage.getItem("user"));
    await httpReauest(
      "POST",
      "/order/approve/" + selected._id,
      {},
      {
        "x-access-token": user.token,
      }
    ).then(({ status }) => {
      if (status === 201) {
        toast.success(
          "سفارش تایید شد و پیامک تایید بزودی به مشتری ارسال میشود"
        );
        setstep(2);
      }
    });
  }

  async function sendOrder() {
    if (!postCode) {
      return toast.error("کد رهگیری خالی است");
    }
    const user = JSON.parse(localStorage.getItem("user"));
    await httpReauest(
      "POST",
      "/order/sended/" + selected._id,
      { postCode },
      {
        "x-access-token": user.token,
      }
    ).then(({ status }) => {
      if (status === 201) {
        toast.success("سفارش تغییر وضعیت به ارسال شده شد");
      }
    });
  }
  return (
    <div>
      <div className="w-full">
        <div className=" mb-10">
          <h1 className="title">سفارشات</h1>
          <p>روند ارسال سفارش به شرح زیر است : </p>
          <p>
            1 : سفارش را کاربر در سایت ثبت میکند و پیامک به ادمین میرود برای
            تایید سفارش و ادمین هم سفارش را چک میکند و تایید میکند
          </p>
          <p>
            2 : پس از تایید سفارش ادمین محصول را به مشخصات پست میکند و شماره
            رهگیری را در همین قسمت وارد میکند به کاربر اطلاع داده میشود شماره
            رهگیری تا بقیه پیگیری ها رو از سمت پست داشته باشه
          </p>
        </div>

        {loading ? (
          <Loading />
        ) : (
          <div className="grid lg:grid-cols-3 gap-3">
            {products?.map((item, i) => (
              <OrderCard key={i} order={item} setloading={setLoading} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default OrdersPage;
