import { useFormik } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import { httpReauest } from "../../../utils/httpRequest";
import * as Yup from "yup";
import { BASE_URL, CDN_BASE_URL } from "../../../config";
import Loading from "../Loading";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";

const EditSubProductForm = () => {
  const [img, setImg] = useState();
  const [images, setImages] = useState([]);
  const [previews, setPreview] = useState([]);
  const [previewImg, setPreviewImg] = useState();
  const [categorys, setCategorys] = useState();
  const [vehicles, setVehicles] = useState();
  const [subproducts, setsubproducts] = useState();
  const [selectedVehicle, setselectedVehicle] = useState();
  const [loading, setloading] = useState();

  const toDataURL = (url) =>
    fetch(url, { mode: "no-cors" })
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const file = new File([blob], url.split("/").pop(), {
                type: blob.type,
              });
              resolve(file);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      makingFee: Yup.string().required("الزامی است"),
      weight: Yup.string().required("الزامی است"),
      balance: Yup.string().required("الزامی است"),
    });
  };

  async function deleteImg(index) {
    setPreview((students) => students.filter((s, i) => i != index));
    setImages((students) => students.filter((s, i) => i != index));
  }

  const formik = useFormik({
    initialValues: {
      makingFee: "",
      weight: "",
      balance: "",
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      setloading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      const formdata = new FormData();
      formdata.append("weight", values.weight);
      formdata.append("balance", values.balance);
      formdata.append("makingFee", values.makingFee);

      await httpReauest("PUT", "/product/sub/" + selectedVehicle?._id, values, {
        "x-access-token": user.token,
      }).then((dat) => {
        if (dat?.status == 201) {
          toast.success("محصول تغییر یافت");
          setloading(false);
          handleChange({ target: { value: selectedVehicle.product } });
          formik.resetForm();
          setselectedVehicle(null);
        }
      });
    },
  });

  useEffect(() => {
    getData();
  }, []);

  async function setPreviwImages(i) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreview((current) => [...current, reader.result]);
    };
    reader.readAsDataURL(i);
  }

  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  async function getData() {
    setloading(true);

    await httpReauest("GET", "/category", {}, {}).then(({ data }) => {
      setCategorys(data?.data);
    });

    await httpReauest("GET", "/product/names/dash", {}, {}).then(({ data }) => {
      setVehicles(data?.data);
    });
    setloading(false);
  }

  async function handleChange(e) {
    setImages([]);
    setPreview([]);
    const { data } = await httpReauest(
      "GET",
      "/product/" + e.target.value,
      {},
      {}
    );

    setsubproducts(data?.data?.subproducts);
    setselectedVehicle(null);
    // formik.setFieldValue("name", newVehicle.name);
    // formik.setFieldValue("desc", newVehicle.desc);
    // formik.setFieldValue("category", newVehicle.category);
    // formik.setFieldValue("weight", newVehicle.weight);
    // formik.setFieldValue("balance", newVehicle.balance);
  }

  async function handleChangeSub(e) {
    const finded = subproducts.find((item) => item._id == e.target.value);

    setselectedVehicle(finded);
    formik.setFieldValue("weight", finded.weight);
    formik.setFieldValue("makingFee", finded.makingFee);
    formik.setFieldValue("balance", finded.balance);
  }

  async function handleDelete(params) {
    const user = JSON.parse(localStorage.getItem("user"));
    await fetch(BASE_URL + "/product/sub/" + selectedVehicle?._id, {
      method: "DELETE",
      headers: { "x-access-token": user.token },
    })
      .then((dat) => {
        if (dat?.status == 201) {
          toast.success("محصول حذف شد");
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  }

  return (
    <div>
      <h2 className="text-[20px] mb-8 font-bold p-3">فرم تغییر زیر محصول</h2>
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6"
      >
        <span className="relative">
          <select
            id="vehicle"
            name="vehicle"
            onChange={handleChange}
            className={` w-full form-brand h-[48px]`}
          >
            <option>{loading && <Loading />}</option>
            {vehicles?.map((e) => {
              return <option value={e._id}>{e.name}</option>;
            })}
          </select>
          <p className="on-border font-bold">انتخاب محصول*</p>
        </span>
        {subproducts && (
          <span className="relative">
            <select
              id="vehicle"
              name="vehicle"
              onChange={handleChangeSub}
              className={` w-full form-brand h-[48px]`}
            >
              <option>{loading && <Loading />}</option>
              {subproducts?.map((e) => {
                return <option value={e._id}>{e.weight} gr</option>;
              })}
            </select>
            <p className="on-border font-bold">انتخاب زیر محصول*</p>
          </span>
        )}
        {selectedVehicle && (
          <>
            <span className="relative">
              <input
                placeholder="مثلا : 1.350"
                id="weight"
                name="weight"
                value={formik.values.weight}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="number"
                className="w-full form-brand h-[48px]"
              />
              {formik.errors.weight && formik.touched.weight && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.weight}
                </small>
              )}
              <p className="absolute translate-y-[-50%] top-[24px] left-4">
                gr
              </p>
              <p className="on-border font-bold">وزن*</p>
            </span>

            <span className="relative">
              <input
                placeholder="مثلا : 10"
                id="balance"
                name="balance"
                value={formik.values.balance}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="number"
                className="w-full form-brand h-[48px]"
              />
              {formik.errors.balance && formik.touched.balance && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.balance}
                </small>
              )}
              <p className="on-border font-bold">تعداد*</p>
            </span>
            <span className="relative">
              <input
                placeholder="مثلا : 10"
                id="makingFee"
                name="makingFee"
                value={formik.values.makingFee}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="number"
                className="w-full form-brand h-[48px]"
              />
              {formik.errors.makingFee && formik.touched.makingFee && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.makingFee}
                </small>
              )}
              <p className="on-border font-bold">اجرت*</p>
            </span>

            <span className="col-span-full flex gap-4">
              {loading ? (
                <Loading />
              ) : (
                <>
                  <button className="btn-brand">تغییر محصول</button>
                  <button
                    type="button"
                    onClick={handleDelete}
                    className=" btn-brand-outline"
                  >
                    {" "}
                    حذف محصول
                  </button>
                </>
              )}
            </span>
          </>
        )}
      </form>
    </div>
  );
};

export default EditSubProductForm;
