
import React, { useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import { httpReauest } from "../../../utils/httpRequest";
import { CDN_BASE_URL } from "../../../config";

const OrderCard = ({ order, setloading }) => {
  const { userId, products, price, _id, status, reciveDate } = order;
  const [sended, setSended] = useState();
  const [recive_Date, setReciveDate] = useState();

  async function handleProgress() {
    const user = JSON.parse(localStorage.getItem("user"));
    await httpReauest(
      "POST",
      "/order/progress",
      { orderId: _id, reciveDate: recive_Date },
      { "x-access-token": user.token }
    )
      .then(() => {
        toast.success("وضعیت سفارش با موفقیت تغییر یافت");
        setloading(true);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  }

  async function handleRecive() {
    const user = JSON.parse(localStorage.getItem("user"));
    await httpReauest("POST", "/order/recived", { orderId: _id }, { "x-access-token": user.token })
      .then(() => {
        toast.success("وضعیت سفارش با موفقیت تغییر یافت");
        setloading(true);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  }

  return (
    <div item xs={12} width={"100%"} md={6} lg={4} className="border rounded p-4">
      <div>
        <span className="flex justify-between py-1" >
          <p variant="caption" color={"#fff"}>
            نام کاربر:
          </p>
          <p variant="caption" color={"#fff"}>
            {userId?.f_name} {userId?.l_name}
          </p>
        </span>
        <span className="flex justify-between py-1" >
          <p variant="caption" color={"#fff"}>
            آدرس:
          </p>
          <p variant="caption" color={"#fff"}>
            {userId?.address}
          </p>
        </span>
        {userId?.desc && (
          <span className="flex justify-between py-1" >
            <p variant="caption" color={"#fff"}>
              متن توضیحی:
            </p>
            <p variant="caption" color={"#fff"}>
              {userId?.desc}
            </p>
          </span>
        )}

        <span className="flex justify-between py-1" >
          <p variant="caption" color={"#fff"}>
            کد پستی:
          </p>
          <p variant="caption" color={"#fff"}>
            {userId?.postalCode}
          </p>
        </span>
        <span className="flex justify-between py-1" >
          <p variant="caption" color={"#fff"}>
            شماره همراه:
          </p>
          <p variant="caption" color={"#fff"}>
            {userId?.phone}
          </p>
        </span>
        <span className="flex justify-between py-1" >
          <p variant="caption" color={"#fff"}>
            قیمت نهایی:
          </p>
          <p variant="caption" color={"#fff"}>
            {price?.toLocaleString()}تومان
          </p>
        </span>
        <span className="flex justify-between py-1" >
          <p variant="caption" color={"#fff"}>
            وضعیت سفارش:
          </p>
          <p variant="caption" color={"#fff"}>
            {status}
          </p>
        </span>
        {reciveDate && (
          <span className="flex justify-between py-1" >
            <p variant="caption" color={"#fff"}>
              تاریخ تحویل:
            </p>
            <p variant="caption" color={"#fff"}>
              {reciveDate}
            </p>
          </span>
        )}
        <span className=" py-1"  flexDirection="column">
          <p className="w-full" color={"#fff"}>
            محصولات:
          </p>
          {products?.map((product) => {
            return (
              <span className="flex justify-between py-1 w-full">
                <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <img style={{ width: "80px" }} src={CDN_BASE_URL + product?.img} />
                  <p variant="overline" color={"#fff"}>
                    {product?.name}
                  </p>
                </span>
                <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <p variant="caption" color={"#fff"}>
                    تعداد: {product?.count}
                  </p>
                </span>
              </span>
            );
          })}
        </span>
        {status == "دریافت شده" ? (
          <span className="flex justify-between py-1" alignItems="center" justifyContent="center" gap={2}>
            {" "}
            <FaCheckCircle color="green" size={45} />
            <p variant="caption" color={"#fff"}>
              تحویل داده شده
            </p>
          </span>
        ) : status == "در حال اماده سازی" ? (
          sended ? (
            <>
              <span
                className="flex justify-between py-1"
                py={1}
                mb={1}
                alignItems="center"
                justifyContent="space-between"
              >
                <p variant="caption" color={"#fff"}>
                  تاریخ تحویل :
                </p>
                <span item xs={6}>
                  <input
                    id="reciveDate"
                    name="reciveDate"
                    className="form-brand"
                    onChange={(e) => setReciveDate(e.target.value)}
                  />
                </span>
              </span>
              <button className="btn-brand" onClick={handleProgress} variant="contained">
                تایید
              </button>
            </>
          ) : (
            <button className="btn-brand" onClick={() => setSended(true)} variant="contained">
              تغییر وضعیت سفارش به ارسال شده
            </button>
          )
        ) : (
          <button className="btn-brand" onClick={handleRecive} variant="contained" style={{ background: "green" }}>
            تغییر وضعیت سفارش به دریافت شده
          </button>
        )}
      </div>
    </div>
  );
};

export default OrderCard;